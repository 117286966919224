th {
    text-transform: none !important;
}

td:focus, td:focus-visible {
    //outline: 1px solid #F1A501 !important;
    //border-radius: 2px;
    //outline-offset: 0px;
    outline: none !important;
    background: #E8E9BE;
    //background: #FDF1C6;
}

table input:disabled {
    opacity: 1 !important;
}

th, td {
    height: 35px; //Table always stretch so height is fine
    border-color: #B2B2B2;
}

#dooit-result-table {
    width: 100%;
    border-collapse: separate;
    background: white;
    //table-layout: fixed;
}

#dooit-result-table td, #dooit-result-table th {
    border-right: 1px solid #B2B2B2;
    border-bottom: 1px solid #B2B2B2;
}

.sticky-col {
    z-index: 1;
    position: sticky;
    background-color: white;
}

.sticky-col:first-child {
    border-left: 1px solid #B2B2B2;
}

#dooit-input-table td, #dooit-input-table th {
    border-width: 1px;
}

.forceRedColor * {
    color: red !important;
}
